import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"
import Spacer from "../spacer"

import Row from "../grid/row"
import Col from "../grid/column"

const Top = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colorPaleGrey};
  padding-bottom: ${rem(20)};
`

const Subheading = styled.h3.attrs({ className: `styled-h3` })`
  line-height: 1.45;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(10)};
  }
`

const Content = styled.div`
  text-align: center;

  p {
    color: ${(props) => props.theme.colorPaleGrey};
    width: ${rem(260)};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${rem(20)};
    max-width: 100%;
  }
`

const ColHeading = styled.h5.attrs({ className: `styled-h4` })`
  margin-top: ${rem(20)};
`

const LineBottom = styled.div`
  height: ${rem(40)};
  background: ${(props) => props.theme.colorTeal};
  margin-top: ${rem(10)};
`

const ClickWay = (props) => (
  <React.Fragment>
    <Container>
      <Top>
        <Spacer top>
          <h2 className="styled-h2">{props.title}</h2>

          <Subheading>{props.subtitle}</Subheading>
        </Spacer>
      </Top>

      <Spacer bottom>
        <Row>
          {props.items?.map((item, index) => (
            <Col smallUp={4} key={index}>
              <Spacer top>
                <Content>
                  <div className="styled-label">{item.label}</div>

                  <ColHeading>{item.title}</ColHeading>

                  <p className="styled-p">{item.description}</p>
                </Content>
              </Spacer>
            </Col>
          )) ?? null}
        </Row>
      </Spacer>
    </Container>

    <LineBottom />
  </React.Fragment>
)

ClickWay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
}

export default ClickWay
