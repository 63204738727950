import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Container from "../../container"
import Spacer from "../../spacer"

import TeamSection from "./team-section"
import { GrayH2Heading } from "../../common/heading"

const El = styled.div`
  background: #f2f2f2;
`

const Team = (props) => (
  <El data-testid="team-block">
    <Spacer top bottom>
      <GrayH2Heading>{props.title}</GrayH2Heading>
    </Spacer>

    <Container>
      <Spacer bottom>
        {props.categories.map((item, index) => (
          <TeamSection number={index} {...item} key={index} />
        ))}
      </Spacer>
    </Container>
  </El>
)

Team.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array.isRequired,
}

export default Team
