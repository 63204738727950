import React, { useState } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { motion, AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"

import Row from "../../grid/row"
import Col from "../../grid/column"

import TeamMember from "./team-member"

const ExpandPlus = styled.div`
  position: absolute;
  top: 50%;
  margin-top: ${rem(-14)};
  width: ${rem(28)};
  height: ${rem(28)};
  right: 0;
  transition: 0.5s;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #000;
    right: 0;
    top: 50%;
    position: absolute;
  }

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background: #000;
    right: 0;
    left: 50%;
    position: absolute;
  }

  ${(props) =>
    props.isOpen &&
    css`
      &::after {
        opacity: 0;
      }
    `}
`
/* c8 ignore next 16 */
const TabTop = styled.div`
  padding: ${rem(20)} 0;
  border-bottom: 1px solid ${(props) => props.theme.colorSand};
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.isOpen &&
    css`
      ${ExpandPlus} {
        opacity: 0;
        transform: rotate(90deg);
      }
    `}
`

const TeamMemberWrapper = styled.div`
  width: ${rem(242)};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: ${rem(40)};
`

const TeamSection = (props) => {
  const [isOpen, toggleTeam] = useState(props.number == 0 ? true : false)

  return (
    <div>
      <div
        data-testid="isOpenToggle"
        onClick={() => {
          toggleTeam(!isOpen)
        }}
      >
        <Row>
          <Col smallUp={12}>
            <TabTop>
              <h4 className="styled-h4">{props.title}</h4>

              <ExpandPlus isOpen={isOpen} />
            </TabTop>
          </Col>
        </Row>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            style={{ overflow: `hidden` }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: `auto` }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ ease: `easeOut`, duration: 0.5 }}
          >
            <Row>
              {props.people.map((item, index) => (
                <Col smallDown={6} smallUp={3} key={index}>
                  <TeamMemberWrapper>
                    <TeamMember hasModal {...item} />
                  </TeamMemberWrapper>
                </Col>
              ))}
            </Row>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

TeamSection.propTypes = {
  title: PropTypes.string,
  people: PropTypes.array,
  number: PropTypes.number,
}

export default TeamSection
