import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Hero from "../components/hero-with-content"
import ClickWay from "../components/page-about/click-way"
import TeamBlock from "../components/page-about/team"

import { personFromTeamMember } from "../utils/team-member"
import { clickWayFromNodes } from "../utils/click-way"

class TeamPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const team = this.props.data.team.nodes
    const clickWay = clickWayFromNodes(team)
    const departments = team.length ? team[0].departments : []

    const categories = departments.map((department) => {
      return {
        title: department.departmentName,
        /* c8 ignore next 5 */
        people: department.teamMembers
          ? department.teamMembers.map(personFromTeamMember)
          : [],
      }
    })

    const content = {
      meta: {
        title: team.length ? team[0].metaTitle : `About Us | Our Team`,
        description: team.length
          ? team[0].metaDescription.metaDescription
          : `We are a passionate & diverse cross-functional team committed to advancing digital medicine to make it evidence-based, accessible to patients, and easy to use.`,
        keywords: team.length
          ? team[0].keywords
          : `ClickTx, Digital Therapeutics, DTx, Advancing Digital Medicine, Digital Therapies, Clinical Practice, healthcare`,
      },
      heroBlock: {
        title: team.length ? team[0].title : `Our Team`,
        content: team.length
          ? team[0].content.content
          : `We are a passionate and diverse cross-functional team of engineers, designers, data scientists, product managers, clinicians, scientists, privacy and regulatory experts. We are committed to advancing digital medicine to make it evidence-based, accessible to patients, and easy to use. It’s our core belief that high-quality digital therapies can offer safe and effective treatments, and will become a routine part of standard of care clinical practice.`,
        /* c8 ignore next 3 */
        bgColor: (props) => props.theme.colorMint,
        textColor: (props) => props.theme.colorWhite,
      },
      clickWay,
      team: {
        title: team.length ? team[0].title : `Our Team`,
        categories,
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero {...content.heroBlock} />

        <ClickWay {...content.clickWay} />

        <TeamBlock {...content.team} />
      </React.Fragment>
    )
  }
}

TeamPageContent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.any,
}

const TeamPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        team: allContentfulTeam(filter: { node_locale: { eq: "en-US" } }) {
          nodes {
            title
            metaTitle
            keywords
            metaDescription {
              metaDescription
            }
            clickWay: theClickWay {
              title
              subtitle
              items {
                title
                description
                label
              }
            }
            departments {
              departmentName
              teamMembers {
                credentialsSuffix
                workTitle
                lastName
                linkedInUrl
                firstName
                description {
                  description
                }
                qualifications
                profileImage {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 242
                    placeholder: BLURRED
                    backgroundColor: "#000000"
                  )
                }
              }
            }
            content {
              content
            }
          }
        }
      }
    `}
    render={(data) => <TeamPageContent data={data} {...props} />}
  />
)

export default TeamPage
